import React, { useEffect, useContext } from "react"
import BannerContext from "../lib/context"
import Helmet from "react-helmet"

import Layout from '../components/layout'
import Header from '../components/Header'
import Section, { TextSection, ContainerSection, BackgroundSection } from '../components/Section'


import { getHomeHeader, getStaticPageData } from "../lib/API"
import { useData, useLocalData } from "../lib/hooks"
import BannerSection from "../components/BannerSection"


// import TotsImage from '../assets/images/home/parents-and-tots.jpg'
import TotsImage from '../assets/images/groups/1.jpg'


// import PastoralImage from '../assets/images/home/pastoral-care3.jpg'
import PastoralImage from '../assets/images/core/PastoralCare2.jpg'
import CafeImage from '../assets/images/home/cafe.jpg'
import ShelterImage from '../assets/images/home/shelter.jpg'
// import FoodBankImage from '../assets/images/home/food-bank.jpg'
import FoodBankImage from '../assets/images/general/greenwich-food-bank-logo.webp'

import PersonPersonImage from '../assets/images/home/person-to-person2.jpg'
import CraftsImage from '../assets/images/general/25.jpg'





export default () => {

    const siteTitle = "Blackheath and Charlton Baptist Church - Serving"
    const pageName = 'serving-banners'
    const pageData = useContext( BannerContext )[pageName]

    // const pageData = useLocalData( getStaticPageData, pageName, pageName )
    const { header, banners } = pageData || { header: null, banners: null }
    const headerImages = header ? header.images : []
    const headerTitle = header ? header.title : 'Serving Our Community'
    
    // const headerImages = useData( getHomeHeader )
    // const headerTitle = "Serving Our Community"
    // const headerIcon = <span className="icon major"><i className="fas fa-seedling"></i></span>

    
    return (
        <div id="serving-page">
            <Helmet title={siteTitle} />
            <Header images={headerImages} headerTitle={headerTitle} tint={0.3}/>

            {/* <BackgroundSection 
                id="parents-and-tots" 
                title="Parents & TOTS"
                description="TOT, our parents and toddlers’ group, is open to members of our congregation and the community every Tuesday from 9am-11am. All are welcome."
                style="style1"
                background={[ TotsImage ]}
                alt
                blur={0.1}
                tint={0.25}
                
            /> */}

            <BackgroundSection 
                id="pastoral-care" 
                title="Pastoral Care"
                style="style1"
                background={[ PastoralImage ]}
                description="The uncertainty created over the last few months by the pandemic and its consequential events, has impacted many lives in our community. The pastoral care team at BCBC are available to talk to and walk alongside anyone who is facing hardships or challenges in their life. We believe that our love for Jesus Christ should find expression in the way we love and serve members of our congregation and our wider community. This belief is central to the commitment we have to serve our community. 
Please contact us if you would like a member of our pastoral team to come talk to you."
                // alt
                tint={0.45}
                
                
            />

            {/* <BackgroundSection
                id="corner-stop-cafe" 
                background={[ CafeImage ]}
                title="Corner Stop Cafe"
                description={`Our café is open from 9:30am-12:30pm on Tuesdays, Wednesdays and Thursdays. We welcome anyone passing by, or planning to come, whether from our community or church family. Come for a friendly chat and sample our snacks and sandwiches.

Our café food is very reasonably priced to make it affordable for everyone in our community. We serve teas, coffees, soup and hot chocolate, as well as cold drinks. Come, too, if you would just like a chat, even if you do not wish to buy our goodies.

Looking forward to welcoming you for a friendly chat.`}
                tint={0.35} 
                alt
            /> */}

            <BackgroundSection
                id="person-to-person" 
                style="style2"
                title="Person to Person"
                background={[PersonPersonImage]}
                description={`Our vision is to get alongside the retired and elderly in our community, those in Sheltered Housing, Care Homes or in their own homes. 
                
                As loneliness is often a problem, we would plan to share Bible passages, pray and be ‘Christ’ to them, who Himself met people at their point of need, whether physical, emotional, psychological or practical. 
                
                The well-being of our neighbours in the community will be our focus and would be underpinned by prayer`
                }
                tint={0.3}
                
            />

            <BackgroundSection
                id="thursday-craft" 
                style="style2"
                title="Thursday Craft Group"
                background={[ CraftsImage ]}
                description={`Our group meets in Corner Stop from 10 am – 12 noon and everyone is welcome to come.
                You can bring your own work / needlework / knitting / crochet or other craft.
                Jumpers, hats and blankets we make go to Christian Hope International and are sent overseas to needy people in Eastern Europe.
                Muffs that we make are ‘Twiddle Muffs’ and go to homes or hospitals which care for those with dementia to keep their hands warm and occupied.
                `
                }
                opacity={0.5}
                tint={0.5}                
            />

            <BackgroundSection
                id="homeless-shelter" 
                style="style2"
                title="Homeless Shelter"
                background={[ ShelterImage ]}
                description={`Over the past few years we have been one of the centres for Greenwich Night Shelter. 
                Due to the current pandemic, this has been on hold. 
                However, it is our hope that we will be able to offer this and other key services that are essential to the wellbeing of members of our community in the very near future.`}
                tint={0.2}
            />

            <Section
                id="food-bank" 
                title="Food Bank"
                image={[ FoodBankImage ]}
                description="Greenwich Foodbank distribution takes place in our building every Thursday 11am-1pm. Please contact a member of the team if you have a need or drop by for a chat."
                tint={0.4}
                blur={0.05}
                // roundedImage
                buttons={[{external: true, url: 'https://greenwich.foodbank.org.uk/locations/', text: 'Food bank locations'}]}

            />

            <BannerSection banners={banners} />


        </div>
    )
}
